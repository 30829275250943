/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.App {
  height: 100%;
  width: 100%;
  position: relative;
 
  
}

.spline-viewer-background {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

h1 {
  font-family: pxiByp8kv8JHgFVrLCz7Z1xlFQ;
}
p {
  font-family: elementskit;
}
h2 {
  font-family: pxiEyp8kv8JHgFVrJJfecg;
}

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url('../public/fonts/myfonts.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}