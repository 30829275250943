


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* card animation */
.animate-pop-up {
  animation: pop-up 0.5s ease-out forwards;
}

@keyframes pop-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* card slider  */
.card1 {
 
  padding: 2rem;
  width: 100%;
  height: 100%;
/* border: 1px solid black; */
 /* text-align: left; */
}

.mycardtext{

  height: 110px;
}

.card {
  background-color: rgba(243, 244, 246, 1);
  padding: 2rem;
  width: 100%;
  height: 20rem;

  border-radius: 10px;
  box-shadow: 0 20px 30px -20px rgba(5, 5, 5, 0.24);
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.header .image {
  height: 8rem;
  width: 8rem;
  border-radius: 9999px;
  object-fit: cover;

  margin: auto;
}

.name {
  text-align: center;
  margin-top: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, 1);
}

.message {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 1rem;
  color: rgba(107, 114, 128, 1);
  text-align: center;
}

.slider {
}
.maincard {
  background: black;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  
}


/* locomotive */
.locomotive-text-enter {
  animation: locomotive-text-enter 1s ease-in-out forwards;
}

@keyframes locomotive-text-enter {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


/* flipcard */
.card {
  width: 300px;
  height: 400px;
  perspective: 1000px;
}

.card-inner {

  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

/*  */

.container > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  pointer-events: none;
  justify-content: start !important;
  font-family: 'Kanit', sans-serif;
  line-height: 0px;
  text-transform: uppercase;
}

.number {
  font-size: 300px;
  color: #545864;
}

.number span {
  display: inline-block;
  position: relative;
}

.slopeBegin {
  background-color: #20232f;
  clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
}

.slopeEnd {
  clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%);
}

.slopeBegin,
.slopeEnd {
  position: absolute;
  width: 170%;
  height: 100%;
  cursor: pointer;
}

.pink {
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  background: linear-gradient(to right, tomato 0%, gold 100%);
}


/* boder */