.slide {
  position: relative;
  display: inline-block;
}

.text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(44, 125, 231);
  font-size: 5vh;
  font-weight: bolder;
  text-align: center;
  width: 100%;
}

.text1 {
  position: absolute;
  top: 30%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  font-size: 5vh;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  text-shadow: 8px 2px 12px #000000;
}

.mybtn {
  padding-top: 30px;
  position: absolute;
  top: 60%;
  width: 100%;
  align-items: center;
}
.btntext {
  background: #04b299;
  padding: 15px;
  color: white;
  border-radius: 10px;
 
}
.btntext:hover{
  background: #04b277;
}
.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f9fafb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .links {
    display: none;
  }

  .ml-72 {
    margin-left: 0;
  }

  #mobile-menu {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 1rem;
    background-color: #f9fafb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  #mobile-menu a {
    color: #333;
  }
}


/* stick the form */
.wave-pattern {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: url("https://organizein.com/wp-content/uploads/2021/03/wave.svg");
  z-index: -1;
}

/* page */
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.page > div {
  touch-action: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  will-change: transform;
  box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100%;
}
